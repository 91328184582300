<template lang="pug">
  tr(:class="{ updated }")
    td
      AppDatepicker.date-input(
        :value="start",
        :class="{ invalid: period.start.$error }"
        :disabled-date="disabledStartDates"
        @change="changePeriod('start', $event)"
      )
    td
      AppDatepicker.date-input(
        :value="end"
        :class="{ invalid: period.end.$error }"
        :disabled-date="disabledEndDates"
        @change="changePeriod('end', $event)"
      )
    td.remove-action
      FaIcon(
        icon="trash-alt"
        @click="$emit('remove-period', index)"
      )
</template>

<script>
  // misc
  import { isNull } from "lodash-es"
  import { startOfDay, endOfDay } from "date-fns"

  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppInput: () => import("@/components/elements/AppInput"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withSorting],

    props: {
      index: {
        type: Number,
        required: true
      },
      updated: {
        type: Boolean,
        default: false
      },
      period: {
        type: Object,
        required: true
      }
    },

    computed: {
      start() {
        return this.period.start.$model
      },

      end() {
        return this.period.end.$model
      }
    },

    methods: {
      disabledStartDates(date) {
        return !isNull(this.end) && date > startOfDay(this.end)
      },

      disabledEndDates(date) {
        return !isNull(this.start) && date <= endOfDay(this.start)
      },

      changePeriod(key, date) {
        this.$emit("update-period", { periodIndex: this.index, period: { ...this.period.$model, [key]: date } })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/variables.sass"

  tbody
    tr
      &.updated
        background-color: $default-purple-light

      td
        &.remove-action
          padding: 0
          color: $default-purple

          svg
            cursor: pointer

        padding: 5px 10px

        .date-input
          width: 100%

        &:nth-child(2)
          padding-right: 5px

          &.invalid
            ::v-deep
              input
                +default-invalid-input

        ::v-deep
          input
            border-color: $default-purple !important
            height: 32px !important
</style>
